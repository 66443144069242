<template >
    <v-app>
        <HeadText type="Dashboard">
        </HeadText>
        <v-container class="pl-2 pl-md-16 pr-4 about_page" fluid >
            <v-row no-gutters style="padding-top: 20px;">
                <v-col class="" cols="12">
                    <span class="my-profile">
                        About Leita
                    </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="" cols="12">
                    <v-divider style="border-color: #206297; border-width: 2px;">
                    </v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="11" lg="5" md="8" sm="8" xl="5">
                    <span role="button" @click="goTo('story')" class="about_link ml-0">Project background</span>
                    <span role="button" @click="goTo('source')" class="about_link ml-0">Resources</span>
                    <span role="button" @click="goTo('why_ads')" class="about_link ml-0">Why ads</span>
                    <span role="button" @click="goTo('cookie')" class="about_link ml-0">Cookies and Privacy</span>
                </v-col>
            </v-row>
            
            <v-row no-gutters style="flex-wrap: nowrap;" class="pt-10">
                    <v-col style="min-width: 100px; max-width: 100%;">
                        <v-row>
                            <v-col cols="12">
                                <img height="36px" v-bind:src="require('@/assets/images/quote_open.png')"/>
                            </v-col>
                        </v-row>
                        <v-row class="pt-0">
                            <v-col class="justify-space-between" cols="auto">
                                <v-row>
                                    <v-col cols="12">
                                        <h3>
                                            Leita [´lei:ta] : “to seek, to search”
                                        </h3>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p>
                                            Since before we spoke astronomy, and the empires of the world emerged, man has been on a quest.
                                            Through millennia we have sought to discover the secrets of nature, and questioned what it is to be human.
                                            We have built upon the knowledge of our ancestors, to puzzle out man´s place in the universe.
                                        </p>
                                        <p>
                                            In the ancient tongue, the word for this quest was “Leita” – the great search for knowledge.
                                            
                                        </p>
                                        <p>
                                            With the Leita open index search, we hope to partake in this ancient quest, and help you puzzle your way into a better future…
                                            
                                        </p>
                                    </v-col>
                                </v-row>
                                
                            </v-col>
                        </v-row>
                        <v-row class="pt-0 pb-16">
                            <v-col cols="12">
                                <img height="36px" v-bind:src="require('@/assets/images/quote_close.png')"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h4>
                                    About Leita
                                </h4>
                                <p>
                                    New principles and guidelines for Open Access mean that the scientific publishing ecosystem is changing.
                                </p>
                                <p>
                                    Leita is an innovative Open Access Platform that helps you navigate this new open research map using advanced technology and information architecture. It enables you to find the once closed information now available for all.
                                </p>
                                <p>
                                    The portal is entirely free of charge for you to use. Maintenance and updates are financed by advertisers that comply with our policy.
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col ref="story">
                                <h4>
                                    Project background
                                </h4>
                                <p>Access to recent, peer-reviewed research is necessary to keep up to date with scientific discovery and practice. </p>
                                <p>Knowledge-driven institutions like universities, colleges, hospitals, financial institutions etc., often fulfil this demand by subscribing to a multitude of databases to give faculty, researchers, and students access to research articles and data. Unfortunately, these databases usually follow a traditional "pay-to-read" subscription model, where scientific publications, often resulting from publicly-funded research projects, are open to subscribers only. </p>
                                <p>This practice is changing as in September 2018, the European Commission and the European Research Council (ERC) launched the cOAlition S initiative. COAlition S is an international consortium, not limited to EU members, of research funding and performing organisations which, through their "Plan S", states that:</p>
                                <p><i>“With effect from 2021, all scholarly publications on the results from research funded by public or private grants provided by national, regional and international research councils and funding bodies must be published in Open Access Journals, on Open Access Platforms, or made immediately available through Open Access Repositories without embargo.”</i></p>
                                Read more: <a href="https://www.coalition-s.org" target="_blank">https://www.coalition-s.org</a>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h4>What does this mean?</h4>
                                <p>The introduction of new principles and guidelines for Open Access means that the scientific publishing ecosystem is changing. Research institutions and researchers will have to adhere to new guidelines for conducting research in a reality where sharing and openness are the ideals. And the publishers will have to change accordingly. It will take time to establish new modes of conduct for funding, publishing, retrieving, sharing, and re-using scientific knowledge. But, ultimately, the most significant change for us is that more research articles and data will be made available publicly and openly!</p>
                                <p>… and this is excellent news because it means that a more significant share of the world population will be able to partake in humankind's quest for knowledge to make a better world for us all.</p>
                                <p>And this is what our project is about. Our mission is to help you navigate the new open research map, not by casting aside the aggregated information, which is still locked behind firewalls, but by using advanced technology and information architecture to help you find the information that once was closed but now is open. We will help you search, retrieve, collect, and sort this information and distil it into something you can use in your research projects and life.</p>
                                <p>This is why, for the last couple of years, we have worked on Leita.</p>
 
                                <p>We hope you like what you see and, most importantly, find! </p>
                                    
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h4>We aim to:</h4>
                                <p>Provide a comprehensive and accurate way to find relevant research through database coordination and higher accuracy in results. To achieve this, we have focused on two things:</p>
                                <ol>
                                    <li>Coordinate databases and better facilitate searches in existing databases.</li>
                                    <li>Increase the precision of search results by statistical classification with an integrated knowledge model for research literature. Our goal is to achieve 85% accuracy with explainable AI (XAI) results.</li>
                                </ol>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col ref="why_ads">
                                <h4>Why does Leita have ads?</h4>
                                <p>Leita is a free, but at the end of the day, We, the people behind the service, need to make a living. Therefore, we have chosen to include ads in the free version of Leita. However, we promise that none of the advertisers represents any danger to Leitas objectivity, reproduceability or transparency when you are searching answers for your questions.</p>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col ref='source'>
                                <h4>Resources</h4>
                                <p>To help you navigate the vast amount of research publications and datasets, we depend on other giants, who do an incredible job of indexing and archiving sources of Open Access scientific research every day. We have started with a small group of carefully chosen resources and plan to expand the collaboration with more actors as we go. The first ones out are:</p>
                                <b>CORE</b>
                                <p>CORE is the world's largest aggregator of open access research papers from repositories and journals. CORE provides free and seamless access to millions of research articles aggregated from thousands of Open Access data providers, such as repositories and journals.</p>
                                <b>DOAJ</b>
                                <p>DOAJ is an independent index containing almost 17 500 peer-reviewed, open access journals, covering all areas of science, technology, medicine, social sciences, arts and humanities.</p>
                                <b>PubMed</b>
                                <p>PubMed is a free resource supporting the search and retrieval of biomedical and life sciences literature. The PubMed database contains more than 33 million citations and abstracts of biomedical literature. It does not include full-text journal articles; however, links to the full text are often present when available from other sources, such as PubMed Central (PMC).</p>
                                <b>OpenAlex</b>
                                <p>Formerly OpenAlex was known as Microsoft Academic Graph. OpenAlex is a free and open catalogue of the world's scholarly papers, researchers, journals, institutions, and how they're connected. Leita uses the OpenAlex graph to help you navigate relationships between concepts, articles, authors etc. and includes relationships from Crossref, ORCID, ROR, DOAJ, Unpaywall, PubMed, PubMed Central, ISSN National Centre and the archives arXiv and Zenodo.</p>
                                <b>UnPaywall</b>
                                <p>Unpaywall harvest content directly from over 50,000 journals and open-access repositories from all over the world. It finds the kind of articles you'd see in peer-reviewed scholarly journals like Science or PLOS One, plus the pre-publication versions (Author Accepted Manuscript) of similar work from preprint repositories like arXiv.</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h4 ref='cookie'>Cookies and Privacy</h4>
                                <p> Leita does not gather user data other than what is necessary to deliver the service. Neither do we sell nor give access to data about you to any third parties, beyond what is indirectly unavoidable when you e.g. share your findings on Facebook, Twitter or any other platform that is tracking your user activities. You are welcome to read more about our <a target="_blank" href="/Terms and Conditions OSINT.pdf">Terms and Conditions</a> and <a target="_blank" href="/Leita Privacy Policy EN.pdf">Privacy Policy</a> by clicking on the links.</p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--<v-col cols="2"  style="min-width: 300px;">
                        <v-row>
                            <v-col cols="12">
                                <img v-bind:src="require('@/assets/images/ad_300_1.png')" width="300px"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <img v-bind:src="require('@/assets/images/ad_300_2.png')" width="300px"/>
                            </v-col>
                        </v-row>
                    </v-col> -->
            </v-row>
        </v-container>
        <FooterLink type="self_navigation">
        </FooterLink>
        <Footer>
        </Footer>
    </v-app>
</template>
<script>

    import HeadText from "@/components/home/HomeAppBar";
    import FooterLink from "@/components/home/FooterLink";
    import Footer from "@/components/home/Footer";

export default {
  name: 'AboutUs',
  components: {
    Footer,
    FooterLink,
    HeadText,
  },

  mounted() {
    if (typeof this.$route.query.to != 'undefined') {
        this.goTo(this.$route.query.to);
    }
    this.$root.$off("go_to");
    this.$root.$on("go_to", (to) => {
        this.goTo(to);
    });
    
  },
  methods: {
    goTo(refName) {
        let top = 0;
        let element = this.$refs[refName];
        if(element) {
            top = element.offsetTop;
        }
        window.scrollTo({
            top: top,
            left: 0,
            behavior: 'smooth'
        }); 
    }
  }
}
</script>


